<template>
  <div>
    <b-row>
      <b-col cols="12">
        <custom-breadcrumb :pageTitle="$t('models.school')" :breadcrumb="breadcrumbData"></custom-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-tabs justified fill>
            <!-- CARGAR DOCUMENTO -->
            <b-tab :title="$t('bulk.tabs.upload')" :active="tabIndex == 0" :disabled="isDisabled[0]">
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <b-card-text class="text-muted">
                      {{ $t('bulk.tab_upload.description') }}
                      <br>
                      <br>
                      <div style="text-align:center;">
                        <b-button @click="exportStructure" class="customButton" variant="outline-secondary">
                          <div v-if="downloadStructure">
                            <feather-icon icon="DownloadIcon" />
                            {{ $t('bulk.tab_upload.download_structure') }}

                          </div>
                          <div v-else>
                            <b-spinner small label="Exporting structure" />
                          </div>
                        </b-button>
                        <b-button @click="exportExample" class="customButton" variant="outline-primary">
                          <div v-if="downloadExample">
                            <feather-icon icon="DownloadIcon" />
                            {{ $t('bulk.tab_upload.download_template') }}
                          </div>
                          <div v-else>
                            <b-spinner small label="Exporting Example" />
                          </div>
                        </b-button>
                      </div>
                    </b-card-text>
                    <b-card-text class="text-muted">
                      {{ $t('bulk.tab_upload.description_process') }}
                    </b-card-text>
                  </b-col>
                  <b-col md="12">
                    <!-- INITIAL -->
                    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                      <div class="dropbox">
                        <input type="file" name="fileLogo" :disabled="isSaving"
                          @change="filesChange($event.target.files); fileCount = $event.target.files.length"
                          accept=".xlsx, .xls" class="input-file">
                        <p v-if="isInitial">
                          {{ $t('bulk.tab_upload.upload_placeholder') }}

                        </p>
                        <p v-if="isSaving">
                          <b-spinner label="Loading..." />
                          <br>
                          <br>
                          {{ $t('bulk.tab_upload.upload_loading') }}
                        </p>
                      </div>
                    </form>
                    <div v-if="isSuccess" style="text-align: center"> <!--SUCCESS-->
                      <h3 class="mt-1 mb-0.5">
                        <div v-if="uploadError">
                          <feather-icon icon="AlertTriangleIcon" class="alertUpload" />
                          {{ $t( uploadError ) }}
                        </div>
                        <div v-else>
                          <feather-icon icon="CheckCircleIcon" class="checkUpload" />
                          {{ $t('bulk.tab_upload.upload_success') }}
                        </div>
                      </h3>
                      <ul class="list-unstyled">
                        <li v-for="item in uploadedFiles" :key="item.url">
                          <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
                        </li>
                      </ul>
                    </div>
                    <div v-if="isFailed" style="text-align: center"> <!--FAILED-->
                      <h3 class="mt-1 mb-0.5"><feather-icon icon="XCircleIcon" class="uploadFailed" />
                        {{ $t( uploadError ) }}
                      </h3>
                    </div>
                    <div v-if="isPayment" style="text-align: center"> <!--NO PAYMENT-->
                      <h3 class="mt-1 mb-0.5">
                        <feather-icon icon="XCircleIcon" class="uploadFailed" />
                        {{ $t('bulk.tab_upload.school_admin') }}

                      </h3>
                    </div>
                  </b-col>
                </b-row>
                <div style="text-align:center;">
                  <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="historyBack"
                    v-if="isInitial || isSaving || isPayment">
                    {{ $t('bulk.back') }}
                  </b-button>
                  <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="reset" v-if="isFailed || isSuccess">
                    {{ $t('bulk.retry') }}
                  </b-button>
                  <b-button variant="primary" class="mt-1" type="button"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goNext" :disabled="!isSuccess">
                    {{ $t('bulk.continue') }}
                  </b-button>
                </div>
            </b-tab>
            <!-- VERIFICAR INFORMACIÓN PROFESORES -->
            <b-tab :title="$t('bulk.tabs.verify_teacher')" :active="tabIndex == 1" :disabled="isDisabled[1]" :lazy="true">
                <b-card-title>
                  {{ $t('bulk.tab_summary.teacher.title') }}
                </b-card-title>
                <div v-if="teachersData.length != 0">
                  <b-card-text>
                    {{ $t('bulk.tab_teacher.description') }}
                  </b-card-text>
                  <bulk-table :perPage="perPage" :currentPage="currentPageTeacher" :itemsData="teachersData" :fieldsData="teacherFields" />
                  <div v-if="canVerifyTeacher">
                    <h5 class="mb-1">
                      {{ $t('bulk.table.ok_title') }}
                    </h5>
                    <b-form-checkbox v-model="verifiedTeacher" plain>
                      {{ $t('bulk.table.ok_confirm_check') }}
                    </b-form-checkbox>
                    <p class="text-muted">
                      {{ $t('bulk.table.ok_confirm_message') }}
                    </p>
                    <div style="text-align:center;">
                      <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBack">
                        {{ $t('bulk.back') }}
                      </b-button>
                      <b-button variant="primary" class="mt-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goNext"
                        :disabled="!verifiedTeacher">
                        {{ $t('bulk.continue') }}
                      </b-button>
                    </div>
                  </div>
                <div v-else style="text-align:center;">
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger">
                    {{ $t('bulk.table.validation_error') }}
                  </b-button>
                  <div>
                    <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBackAndReset">
                      {{ $t('bulk.back') }}
                    </b-button>
                  </div>
                </div>
                </div>
                <div v-else>
                  <h4 style="text-align: center; margin: 20px;">
                    <feather-icon icon="AlertTriangleIcon" class="alertUpload" />
                    <br>
                    <br>
                    {{ $t( 'bulk.tab_teacher.empty' ) }}
                  </h4>
                  <div style="text-align:center;">
                      <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBack">
                        {{ $t('bulk.back') }}
                      </b-button>
                      <b-button variant="primary" class="mt-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goNext"
                        :disabled="!verifiedTeacher">
                        {{ $t('bulk.continue') }}
                      </b-button>
                    </div>
                </div>
            </b-tab>

            <!-- VERIFICAR INFORMACIÓN ALUMNOS -->
            <b-tab :title="$t('bulk.tabs.verify_student')" :active="tabIndex == 2" :disabled="isDisabled[2]" :lazy="true">
                <b-card-title>
                  {{ $t('bulk.tab_summary.student.title') }}
                </b-card-title>
                <div v-if="dreamersData.length != 0">
                  <b-card-text>
                    {{ $t('bulk.tab_student.description') }}
                  </b-card-text>
                  <bulk-table :perPage="perPage" :currentPage="currentPageDreamer" :itemsData="dreamersData"
                    :fieldsData="dreamersFields" />
                  <div v-if="canVerifyDreamer">
                    <h5 class="mb-1">
                      {{ $t('bulk.table.ok_title') }}
                    </h5>
                    <b-form-checkbox v-model="verifiedDreamer" plain>
                      {{ $t('bulk.table.ok_confirm_check') }}
                    </b-form-checkbox>
                    <p class="text-muted">
                      {{ $t('bulk.table.ok_confirm_message') }}
                    </p>
                    <div style="text-align:center;">
                      <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBack">
                        {{ $t('bulk.back') }}
                      </b-button>
                      <b-button variant="primary" class="mt-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goNext"
                        :disabled="!verifiedDreamer">
                        {{ $t('bulk.continue') }}
                      </b-button>
                    </div>
                  </div>
                  <div v-else style="text-align:center;">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger">
                      {{ $t('bulk.table.validation_error') }}
                    </b-button>
                    <div>
                      <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBackAndReset">
                        {{ $t('bulk.back') }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h4 style="text-align: center; margin: 20px;">
                    <feather-icon icon="AlertTriangleIcon" class="alertUpload" />
                    <br>
                    <br>
                    {{ $t( 'bulk.tab_student.empty' ) }}
                  </h4>
                  <div style="text-align:center;">
                      <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBack">
                        {{ $t('bulk.back') }}
                      </b-button>
                      <b-button variant="primary" class="mt-1" type="button"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goNext"
                        :disabled="!verifiedDreamer">
                        {{ $t('bulk.continue') }}
                      </b-button>
                    </div>
                </div>
            </b-tab>

            <!-- CONFIRMACIÓN -->
            <b-tab :title="$t('bulk.tabs.summary')" :active="tabIndex == 3" :disabled="isDisabled[3]" lazy>
                <b-card-title>
                  {{ $t('bulk.tab_summary.title') }}
                </b-card-title>
                <b-row v-if="isSuccess">
                  <b-col>
                    <bulk-summary @startDate1="changeTeacherStartDate" @endDate1="changeTeacherEndDate"
                      :title="$t('bulk.tab_summary.teacher.title')" :dateId=1 
                      :adminDates="[adminStartDate, adminEndDate]" 
                      :bulkData="[teacherBackData.created.length, teacherBackData.modified.length, teacherBackData.overwrite.length, Object.keys(teacherBackData.addedGroups).length,teacherBackData.unchanged.length]" 
                      />
                  </b-col>
                  <b-col>
                    <bulk-summary @startDate2="changeStudentStartDate" @endDate2="changeStudentEndDate"
                      :title="$t('bulk.tab_summary.student.title')" :dateId=2 
                      :adminDates="[adminStartDate, adminEndDate]" 
                      :bulkData="[studentBackData.created.length, studentBackData.modified.length, studentBackData.overwrite.length, Object.keys(studentBackData.addedGroups).length,studentBackData.unchanged.length]" 
                      />
                  </b-col>
                </b-row>
                <div style="text-align:center;">
                  <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="end" :disabled="datesSetted()">
                    {{ $t('bulk.end') }}
                  </b-button>
                </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BPopover,
  BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  BTooltip,
  VBPopover,
} from 'bootstrap-vue'
import {
  exportSchoolStructure,
  importSchoolStructure,
  exportSchoolPlantilla,
  fetchSchool,
  implementSchoolStructure,
} from '@/api/routes'
import BulkSummary from './bulk/BulkSummary.vue'
import BulkTable from './bulk/BulkTable.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import Ripple from 'vue-ripple-directive'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_NO_PAYMENT = 4;


export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormInvalidFeedback,
    BLink,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BMediaBody,
    BPagination,
    BPopover,
    BRow,
    BSpinner,
    BTab,
    BTable,
    BTabs,
    BTooltip,
    BulkSummary,
    BulkTable,
    CustomBreadcrumb,
    VBPopover,
  },
  data() {
    return {
      //navigation
      isDisabled: [false, true, true, true],
      tabIndex: 0,
      schoolName: "School",
      schoolId: null,

      //upload file
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      downloadStructure: true,
      downloadExample: true,

      // Validation
      canVerifyTeacher: true,
      canVerifyDreamer: true,
      verifiedDreamer: false,
      verifiedTeacher: false,

      //table
      perPage: 5,
      currentPageTeacher: 1,
      currentPageDreamer: 1,
      teachersData: [],
      teacherFields: [
        { label: this.$t('bulk.table.status'), key: "status" },
        { label: this.$t('bulk.table.row'), key: "row", sortable: "true" },
        // { label: "errors", key: "errors" },
        // { label: "warnings", key: "warnings" },
        // { label: "id", key: "id" },
        { label: this.$t('bulk.table.name'), key: "name", sortable: "true" },
        { label: this.$t('bulk.table.email'), key: "email", sortable: "true" },
        // { label: "password", key: "password" },
        { label: this.$t('bulk.table.added_groups'), key: "addedGroups" },
        { label: this.$t('bulk.table.deleted_groups'), key: "deletedGroups" },
      ],
      dreamersData: [],
      dreamersFields: [
        { label: this.$t('bulk.table.status'), key: "status" },
        { label: this.$t('bulk.table.row'), key: "row", sortable: "true" },
        // { label: "errors", key: "errors" },
        // { label: "warnings", key: "warnings" },
        // { label: "id", key: "id" },
        { label: this.$t('bulk.table.name'), key: "name", sortable: "true" },
        { label: this.$t('bulk.table.email'), key: "email", sortable: "true" },
        // { label: "password", key: "password" },
        { label: this.$t('bulk.table.birthdate'), key: "birthdate", sortable: "true" },
        { label: this.$t('bulk.table.added_groups'), key: "addedGroups" },
        { label: this.$t('bulk.table.deleted_groups'), key: "deletedGroups" },
        { label: this.$t('bulk.table.registration_number'), key: "registration_number", sortable: "true" },
      ],

      //dates
      teacherStartDate: null,
      teacherEndDate: null,
      studentStartDate: null,
      studentEndDate: null,
      adminStartDate: null,
      adminEndDate: null,

      //data estructurada para el back
      teacherBackData: [],
      studentBackData: [],
    }
  },
  watch: {
    tabIndex() {
      if (this.tabIndex == 0) {
        this.isDisabled = [false, true, true, true]
      } else if (this.tabIndex == 1) {
        this.isDisabled = [true, false, true, true]
      } else if (this.tabIndex == 2) {
        this.isDisabled = [true, true, false, true]
      } else if (this.tabIndex == 3) {
        this.isDisabled = [true, true, true, false]
      }
    },
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    isPayment() {
      return this.currentStatus === STATUS_NO_PAYMENT;
    },
    breadcrumbData() {
      if (this.$store.getters['user/isSchool']) {
        return [
          {
            text: this.$t('Schools'),
          },
          {
            text: this.schoolName
          },
          {
            text: 'Carga Masiva',
            active: true,
          },
        ]
      } else {
        return [
          {
            text: this.$t('Schools'),
            to: 'schools'
          },
          {
            to: 'school-view',
            params: {
              id: this.$route.params.id,
            },
            text: this.schoolName
          },
          {
            text: 'Carga Masiva',
            active: true,
          },
        ]
      }
    },
  },
  methods: {
    getStatus(item) {
      if (item.errors.length > 0) {
        return 'errors';
      } else if (item.warnings.length > 0) {
        return 'warnings';
      } else {
        return item.estado;
      }
    },
    showPopover() { // @TODO hacer que se muestre el primer popover
      this.$root.$emit('bv::show::popover', 'popover-button-1')
    },
    //nav
    goNext() {
      this.tabIndex++
    },
    goBack() {
      this.tabIndex--
    },
    async end() {
      if (new Date(this.teacherStartDate).getTime() > new Date(this.teacherEndDate).getTime()) {
        var pivot = this.teacherStartDate
        this.teacherStartDate = this.teacherEndDate
        this.teacherEndDate = pivot
      }
      if (new Date(this.studentStartDate).getTime() > new Date(this.studentEndDate).getTime()) {
        var pivot = this.studentStartDate
        this.studentStartDate = this.studentEndDate
        this.studentEndDate = pivot
      }
      this.historyBack()
      var validateData = {
        "school_id": this.schoolId,
        "Teachers": {
          "initialDate": this.teacherStartDate + " 00:00:00",
          "finalDate": this.teacherEndDate + " 23:59:39",
          "created": this.teacherBackData.created,
          "modified": this.teacherBackData.modified,
          "overwrite": this.teacherBackData.overwrite,
          "unchanged": this.teacherBackData.unchanged,
          "addedGroups": this.teacherBackData.addedGroups,
        },
        "Students": {
          "initialDate": this.studentStartDate + " 00:00:00",
          "finalDate": this.studentEndDate + " 23:59:39",
          "created": this.studentBackData.created,
          "modified": this.studentBackData.modified,
          "overwrite": this.studentBackData.overwrite,
          "unchanged": this.studentBackData.unchanged,
          "addedGroups": this.studentBackData.addedGroups,
        },
      }
      await this.$http.post(implementSchoolStructure, validateData).then(response => {
        // console.log(response)
      }).catch(error => {
        // console.log("Err -> ", error);
      })

    },
    goBackAndReset() {
      this.reset()
      this.tabIndex = 0
      this.canVerifyTeacher = false
      this.canVerifyDreamer = false
    },
    historyBack() {
      window.history.back();
    },
    checkAccess(){
      let data = {
        id: this.$route.params.id
      }
      if(!this.$route.params.id){
        if(this.$store.getters['user/getRole'] == "school-admin"){
          data.id = this.$store.getters['user/isSchool'].id
        } else {
          this.$router.push({name:'schools'})
        }
      } else {
        this.schoolId = this.$route.params.id
      }
      if(data.id){
        this.getSchool(data)
      }
    },
    async getSchool(data) {
      await this.$http.post(fetchSchool, data).then(response => {
        if(response.data.status == 200){
          this.schoolName = response.data.school.name;
          this.schoolId = response.data.school.id;
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })
    },

    // Export
    async exportStructure() {
      if (this.downloadStructure) {
        this.downloadStructure = false

        let data = {
          'school_id': this.schoolId
        }
        await this.$http.post(exportSchoolStructure, data, { responseType: 'blob' }).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.schoolName + '.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        })
        this.downloadStructure = true
      }
    },
    async exportExample() {
      this.downloadExample = false

      let data = {
        school_id: this.schoolId
      }
      await this.$http.post(exportSchoolPlantilla, data, { responseType: 'blob' }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.schoolName + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })

      this.downloadExample = true
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },

    //upload file
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    async filesChange(fileList) {
      this.verifiedDreamer = false
      this.verifiedTeacher = false

      const formData = new FormData();
      formData.append('school_id', this.schoolId);
      formData.append('file', fileList[0]);
      this.uploadedFiles = [];
      this.uploadError = null;
      this.currentStatus = STATUS_SAVING
      await this.$http.post(importSchoolStructure, formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Establecer el encabezado necesario para enviar archivos
        }
      }).then(response => {
        if (response.data.adminPayment != null) {
          if (response.data.adminStartDate != null) {
            this.adminStartDate = response.data.adminStartDate
          }
          if (response.data.adminEndDate != null) {
            this.adminEndDate = response.data.adminEndDate
          }
          this.teachersData = response.data.Teachers.frontData.sort(this.compareItems)
          this.teacherBackData = {
            created: response.data.Teachers.created,
            modified: response.data.Teachers.modified,
            overwrite: response.data.Teachers.overwrite,
            unchanged: response.data.Teachers.unchanged,
            addedGroups: response.data.Teachers.addedGroups,
          }
          
          this.dreamersData = response.data.Students.frontData.sort(this.compareItems)
          this.studentBackData = {
            created: response.data.Students.created,
            modified: response.data.Students.modified,
            overwrite: response.data.Students.overwrite,
            unchanged: response.data.Students.unchanged,
            addedGroups: response.data.Students.addedGroups,
          }
          if (this.teachersData.length != 0 ) {
            if (this.teachersData[0].errors.length != 0) {
              this.canVerifyTeacher = false
            } else {
              this.canVerifyTeacher = true
            }
          } else {
              this.uploadError = 'bulk.tab_upload.teacher_empty'
              this.canVerifyTeacher = true
              this.verifiedTeacher = true
          }
          if (this.dreamersData.length != 0) {
            if (this.dreamersData[0].errors.length != 0) {
              this.canVerifyDreamer = false
            } else {
             this.canVerifyDreamer = true
            }
          } else {
            this.uploadError = 'bulk.tab_upload.dreamer_empty'
            this.canVerifyDreamer = true
            this.verifiedDreamer = true
          }
          this.currentStatus = STATUS_SUCCESS
          if (this.teachersData.length == 0 && this.dreamersData.length == 0) {
            this.uploadError = 'bulk.tab_upload.file_empty'
            this.currentStatus = STATUS_FAILED
          } 
        } else {
          if (response.data.status == 400) {
            this.currentStatus = STATUS_FAILED
            this.uploadError = response.data.msg
          } else {
            this.currentStatus = STATUS_NO_PAYMENT
          }
        }
      }).catch(error => {
        this.uploadError = 'bulk.tab_upload.upload_error'
        this.currentStatus = STATUS_FAILED
      })
    },
    compareItems(a, b) {
      // Ordena primero los elementos con errores
      if (a.errors.length > 0 && b.errors.length === 0) {
        return -1; // 'a' va antes que 'b'
      }
      if (a.errors.length === 0 && b.errors.length > 0) {
        return 1; // 'b' va antes que 'a'
      }

      // Ordena luego los elementos con advertencias
      if (a.warnings.length > 0 && b.warnings.length === 0) {
        return -1; // 'a' va antes que 'b'
      }
      if (a.warnings.length === 0 && b.warnings.length > 0) {
        return 1; // 'b' va antes que 'a'
      }

      switch (a.status) {
        case 'created':
          switch (b.status) {
            case 'created':
              return 0
            case 'modified':
              return 1
            case 'overwrite':
              return 1
            case 'unchanged':
              return -1
          }
        case 'modified':
          switch (b.status) {
            case 'created':
              return -1
            case 'modified':
              return 0
            case 'overwrite':
              return 1
            case 'unchanged':
              return -1
          }
        case 'overwrite':
          switch (b.status) {
            case 'created':
              return -1
            case 'modified':
              return -1
            case 'overwrite':
              return 0
            case 'unchanged':
              return -1
          }
        case 'unchanged':
          switch (b.status) {
            case 'created':
              return 1
            case 'modified':
              return 1
            case 'overwrite':
              return 1
            case 'unchanged':
              return 0
          }
      }
      // Si ninguno de los criterios anteriores se cumple, mantiene el orden original
      return 0;
    },

    // Handle License Dates
    changeTeacherStartDate(starDate) {
      this.teacherStartDate = starDate
    },
    changeStudentStartDate(starDate) {
      this.studentStartDate = starDate
    },
    changeTeacherEndDate(endDate) {
      this.teacherEndDate = endDate
    },
    changeStudentEndDate(endDate) {
      this.studentEndDate = endDate
    },
    datesSetted() {
      if (this.teacherStartDate == null
        || this.teacherEndDate == null
        || this.studentStartDate == null
        || this.studentEndDate == null) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.checkAccess()
    this.reset();
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
}
</script>

<style>
hr {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dropbox {
  outline: 2px dashed #87bced;
  /* the dash box */
  outline-offset: -10px;
  background: #87bced30;
  color: #87bced;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.dropbox:hover {
  background: #87bced70;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.custom-table thead th {
  vertical-align: middle;
}

.custom-table {
  max-height: 460px;
}

.customButton {
  width: 250px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.checkUpload {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: green;
}

.alertUpload {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: orange;
}

.uploadFailed {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: red;
}
</style>