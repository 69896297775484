<template>
  <div>
    <b-table sticky-header="true" :no-border-collapse="true" striped responsive class="custom-table" :per-page="perPage"
      :current-page="currentPage" :items="itemsData" :fields="fieldsData">
      <template #cell(status)="data">
        <div class="text-center">
          <div v-if="data.item.errors.length > 0"> <!-- ERRORS -->
            <b-button :id=data.item.row.toString() v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger">
              <feather-icon icon="AlertOctagonIcon" stroke="red" />
            </b-button>
            <b-popover :target=data.item.row.toString() variant="danger" triggers="focus" placement="top">
              <template #title>
                <span>{{$t('bulk.table.row_error.title')}}</span>
              </template>
              <b-list-group>
                <b-list-group-item v-for="error in data.item.errors" :key="error">
                  {{ $t(error) }}
                </b-list-group-item>
              </b-list-group>
            </b-popover>
          </div>
          <div v-else-if="data.item.warnings.length > 0"> <!-- WARNINGS -->
            <b-button :id=data.item.row.toString() v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-warning">
              <feather-icon icon="AlertCircleIcon" stroke="orange" />
            </b-button>
            <b-popover :target=data.item.row.toString() variant="warning" triggers="focus" placement="top">
              <template #title>
                <span>{{$t('bulk.table.row_warning.title')}}</span>
              </template>
              <b-list-group>
                <b-list-group-item v-for="warning in data.item.warnings" :key="warning">
                  {{ $t(warning) }}
                </b-list-group-item>
              </b-list-group>
            </b-popover>
          </div>
          <div v-else-if="data.item.status === 'created'"> <!-- CREATED -->
            <b-button :id=data.item.row.toString() v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success">
              <feather-icon  icon="UserPlusIcon" stroke="green" />
            </b-button>
            <b-popover :target=data.item.row.toString() variant="success" triggers="focus" placement="top">
              <template #title>
                <span>{{$t('bulk.table.row_created.title')}}</span>
              </template>
            </b-popover>
          </div>
          <div v-else-if="data.item.status === 'overwrite'"> <!-- OVERWRITTEN -->
            <b-button :id=data.item.row.toString() v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-info">
              <feather-icon  icon="Edit2Icon" stroke="blue" />
            </b-button>
            <b-popover :target=data.item.row.toString() variant="info" triggers="focus" placement="top">
              <template #title>
                <span>{{$t('bulk.table.row_traslated.title')}}</span>
              </template>
            </b-popover>
          </div>
          <div v-else-if="data.item.status === 'modified'"> <!-- MODIFIED -->
            <b-button :id=data.item.row.toString() v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-info">
              <feather-icon  icon="FeatherIcon" stroke="blue" />
            </b-button>
            <b-popover :target=data.item.row.toString() variant="info" triggers="focus" placement="top">
              <template #title>
                <span>{{$t('bulk.table.row_modified.title')}}</span>
              </template>
            </b-popover>
          </div>
          <div v-else-if="data.item.status === 'unchanged'"> <!-- UNCHANGED -->
            <b-button :id=data.item.row.toString() v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-dark">
              <feather-icon  icon="UserIcon" />
            </b-button>
            <b-popover :target=data.item.row.toString() variant="dark" triggers="focus" placement="top">
              <template #title>
                <span>{{$t('bulk.table.row_unchanged.title')}}</span>
              </template>
            </b-popover>
          </div>
          <div v-else> <!-- OTHER -->
            <b-button :id=data.item.row.toString() v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-dark">
              <feather-icon  icon="UserIcon" />
            </b-button>
            <b-popover :target=data.item.row.toString() variant="dark" triggers="focus" placement="top">
              <template #title>
                <span>{{$t('bulk.table.row_unchanged.title')}}</span>
              </template>
            </b-popover>
          </div>
        </div>
      </template>
      <template #cell(addedGroups)="data">
        <b-badge v-for="group in data.item.addedGroups" :key="group" variant="success" class="mr-25 mt-25">
          {{ group }}
        </b-badge>
      </template>
      <template #cell(deletedGroups)="data">
        <b-badge v-for="group in data.item.deletedGroups" :key="group" variant="danger" class="mr-25 mt-25">
          {{ group }}
        </b-badge>
      </template>
    </b-table>
    <b-pagination v-model="currentPage" :total-rows="itemsData.length" :per-page="perPage" align="center" />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BListGroup,
  BListGroupItem,
  BPagination,
  BPopover,
  BTable,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BBadge,
    BButton,
    BListGroup,
    BListGroupItem,
    BPagination,
    BPopover,
    BTable,
    VBPopover,
  },
  props: {
    itemsData: {
      type: Array,
      required: true
    },
    fieldsData: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
  directives: {
    Ripple,
  },
}
</script>

<style></style>